import { memo, createContext, useContext } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import  ProfileIcon from '../../assets/images/messede.png'; 

const ActiveNavItemContext = createContext('Blog');


function Brand({ brandName, imgSrc }) {
    return (
        <div className='brand display-flex-row'>
            <div className='brand-img'>
                <img src={imgSrc} alt="" />
            </div>
            <div className='brand-name header-font'>
                <span>{brandName}</span>
            </div>
        </div>
    );
}


function NavItem({ itemName, link, onClick }) {
    const activeNavItem = useContext(ActiveNavItemContext);
    const selectBarClass = itemName === activeNavItem ? 'bottom-bar bb-selected' : 'bottom-bar'

    return (
        <div className='navitem display-flex-col'>
            <div className='navitem-name header-font'>
                <Link to={link} className='nav-link' onClick={onClick}>{itemName}</Link>
            </div>
            <div className={selectBarClass} />
        </div>
    );
}

function NavBar({ activeMenuItem, onSelectMenu }) {
    let menus = [{ name: "Blog", link: "/" }, { name: "Projects", link: "/projects" }, { name: "About", link: "/about" }]
    let navItems = menus.map(menuItem => {
        return (
            // <Link to={menuItem.link}>
            <NavItem key={menuItem.name} itemName={menuItem.name} link={menuItem.link} onClick={() => onSelectMenu(menuItem.name)} />
            // </Link>
        );
    })

    return (
        <div className='navbar display-flex-row'>
            <Brand imgSrc={ProfileIcon} brandName="Messede" />
            <div className='navitems display-flex-row'>
                <ActiveNavItemContext.Provider value={activeMenuItem}>
                    {navItems}
                </ActiveNavItemContext.Provider>
            </div>
            <div className='empty'>
            </div>
        </div>
    );
}

export default memo(NavBar)