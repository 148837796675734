import React from 'react';
import ProjectEntry from '../../components/ProjectEntry/ProjectEntry';

export default function Projects({ projectEntries }) {
    let ProjectEntriesRender = projectEntries.map(projectEntry => {
        return (
            <ProjectEntry projectEntry={projectEntry}/>
        )
    })

    return (
        <div className='content display-flex-col' >
            <div>
                {ProjectEntriesRender}
            </div>
        </div >
    );

}