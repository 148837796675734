import React, { memo } from "react";
import AngularIcon from '../../assets/icons/angular.svg';
import ReactIcon from '../../assets/icons/react.svg';
import GoLangIcon from '../../assets/icons/golang.svg';
import PythonIcon from '../../assets/icons/python.svg';
import ProfileImage from '../../assets/images/messede.png';
import './UserInfo.css';


const TechIconMap = {
    "angular": AngularIcon,
    "react": ReactIcon,
    "golang": GoLangIcon,
    "python": PythonIcon,
}

function UserHeader({ username }) {
    return (
        <div className="user-header display-flex-row">
            <div className="profile-image">
                <img src={ProfileImage} alt="Profile Icon" />
            </div>
            <div className="user-name">
                <span>{username}</span>
            </div>
        </div>
    );
}

function TechIcon({ techName }) {
    return (
        <div className="tech-icon">
            <img src={TechIconMap[techName]} alt="" />
        </div>
    );
}

function TechStack({ technologies }) {
    let techIcons = technologies.map((technology) => {
        return (
            <TechIcon techName={technology} key={technology}/>
        )
    })
    return (
        <div className="tech-icons display-flex-row">
            {techIcons}
        </div>
    );
}

function Work({ workEntries }) {
    function openWorkSite(workSite) {
        if (workSite) {
            window.open(workSite, "_blank")
        }
    }   

    let workList = workEntries.map((work) => {
        return (
            <div className="work-entry display-flex-row" key={work.place}>
                <span className="work-designation">
                    {work.designation}
                </span>
                <span>
                    @
                </span>
                <span className="work-place display-flex-row" onClick={() => openWorkSite(work.site)}>{work.place}</span>
            </div>
        );
    })

    return (   
        <div className="work-list display-flex-col">
            {workList}
        </div>
    );
}

function UserInfo({ user }) {
    return (
        <div className='user-info display-flex-col' >
            <UserHeader username={user.name} />
            <div className="user-title display-flex-row">
                <span>{user.title}</span>
            </div>
            <TechStack technologies={user.technologies} />
            <div className="sep-line-container display-flex-row">
                <div className="separator-line"></div>
            </div>
            <Work workEntries={user.work} />
        </div>
    );
}

export default memo(UserInfo)