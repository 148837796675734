import './ProjectEntry.css';
import OpenNewIcon from '../../assets/icons/open-in-new.svg';
export default function ProjectEntry({ projectEntry }) {

    function OpenLink(link) {
        window.open(link, "_blank")
    }

    let readMore = projectEntry.read_more != "" ? (
        <div className='project-readmore' onClick={() => { OpenLink(projectEntry.read_more) }}>
            <span>Read More...</span>
        </div>
    ) : (
        <div className='project-readmore project-readmore-disabled'>
            <span><strike>Read More...</strike></span>
        </div>
    )

    let openProject = projectEntry.deployment_url != "" ? (
        <div className='btn open-btn'>
            <div className='btn-content display-flex-row' onClick={() => { OpenLink(projectEntry.deployment_url) }}>
                <span><u>Open</u></span>
                <div>
                    <img src={OpenNewIcon} alt="" />
                </div>
            </div>
        </div>
    ) : (
        <div className='btn disabled-btn'>
            <div className='btn-content display-flex-row'>
                <span><u>Open</u></span>
                <div>
                    <img src={OpenNewIcon} alt="" />
                </div>
            </div>
        </div>
    )

    let openSource = projectEntry.source != "" ? (
        <div className='btn source-btn'>
            <div className='btn-content display-flex-row' onClick={() => { OpenLink(projectEntry.source) }}>
                <span><u>Source</u></span>
                <div>
                    <img src={OpenNewIcon} alt="" />
                </div>
            </div>
        </div>
    ) : (
        <div className='btn disabled-btn'>
            <div className='btn-content display-flex-row'>
                <span><u>Source</u></span>
                <div>
                    <img src={OpenNewIcon} alt="" />
                </div>
            </div>
        </div>
    )

    return (
        <div className='project-entry display-flex-row'>
            <div className='project-info display-flex-col'>
                <div className='project-name'>
                    <span>{projectEntry.name}</span>
                </div>
                <div className='project-desc'>
                    <span>{projectEntry.desc}</span>
                </div>
                {readMore}
            </div>
            <div className='project-btns display-flex-col'>
                {openProject}
                {openSource}
            </div>
        </div>
    );
}