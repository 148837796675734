import './ArticleEntry.css';

export default function ArticleEntry({ articleEntry }) {
    function openArticle() {
        if (articleEntry.isExternal) {
            window.open(articleEntry.url, "_blank")
        }
    }   

    return (
        <div className='article-entry display-flex-col'>
            <div className='article-date'>
                <span>{articleEntry.date}</span>
            </div>
            <div className='article-title' onClick={openArticle}>
                <span>{articleEntry.title}</span>
            </div>
            <div className='article-summary'>
                <span>{articleEntry.summary}</span>
            </div>
        </div>
    );
}