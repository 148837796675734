import React from 'react';
import ArticleEntry from '../../components/ArticleEntry/ArticleEntry';
import './Blog.css';

export default function Blog({ articleEntries }) {
    let ArticleEntriesRender = articleEntries.map(entry => {
        return (<ArticleEntry
            articleEntry={entry} key={entry.title}
        />)
    })

    return (
        <div className='content display-flex-col' >
            <div>
                {ArticleEntriesRender}
            </div>
        </div >
    );

}