import React from "react";
import UserInfo from "../../components/UserInfo/UserInfo";

export default function About({ user }) {
    return (
        <div className="content display-flex-col">
            <div>
                <UserInfo user={user} />
            </div>
        </div>
    );
}